/* Pagination */
ngb-pagination > .pagination {
  @apply flex items-center justify-between gap-2;
}

ngb-pagination .page-item {
  @apply h-10 w-10 rounded-main bg-light-bg text-base text-text-primary hover:bg-green-400 hover:text-text-secondary dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

ngb-pagination .page-item:nth-child(2) {
  margin-right: auto;
}

ngb-pagination .page-item:nth-last-child(2) {
  margin-left: auto;
}

ngb-pagination .page-item > a {
  @apply flex h-full w-full items-center justify-center;
}

ngb-pagination .page-item.active {
  @apply bg-green-400 text-text-secondary;
}

ngb-pagination .page-item.disabled {
  @apply pointer-events-none bg-transparent opacity-50;
}

ngb-pagination .page-item > a[aria-label="First"],
ngb-pagination .page-item > a[aria-label="Previous"],
ngb-pagination .page-item > a[aria-label="Next"],
ngb-pagination .page-item > a[aria-label="Last"] {
  @apply rounded-main bg-select-bg text-text-secondary hover:bg-green-400 dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

/* Table */
.adminTable {
  @apply w-full;
}

.adminTable > thead {
  @apply border-min border-stroke-color bg-select-bg dark:border-slate-700 dark:bg-slate-800;
}

.adminTable > thead th {
  @apply p-[0.625rem] text-left text-sm font-normal text-text-secondary dark:text-slate-200;
}

.adminTable > tbody td {
  @apply max-w-[10rem] border-min border-stroke-color bg-box-background p-[0.625rem] text-left text-sm font-normal dark:border-slate-700 dark:bg-slate-800;
}

.adminTable > tbody td > div {
  @apply flex items-center justify-start gap-2;
}

.adminTable > tbody td span {
  @apply truncate;
}
