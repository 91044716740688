.title {
  @apply text-3xl font-bold text-text-primary dark:text-white max-md:text-2xl;
}

.subtitle {
  @apply text-xl font-normal text-text-primary dark:text-white;
}

.title-big {
  @apply text-xl font-normal text-text-primary dark:text-white;
}

.title-modal {
  @apply text-center text-2xl font-bold;
}
